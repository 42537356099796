<template>
  <div class="py-1 pt-0 mb-2 shadow-bottom">
    <p
      class="text-l"
      :class="{'p-1 s shadow-bottom text-gray-600': !child, 'font-medium  text-gray-600': child}">
      {{ question.question }}
    </p>
    <div>
      <p class="mt-1 p-2 sm:col-span-2 sm:mt-0 text-blue-900">
        {{ getAnswer(question.name) }}
      </p>
    </div>
    <div class=""  v-if="question.children.questions.length>0">
      <Question
          class="mt-2"
          v-for="question in question.children.questions"
          :question="question"
          :result="result"
          v-bind:key="question.name">
      </Question>
    </div>
  </div>
</template>

<script>
import get from "lodash-es/get";

export default {
  props: {
    question: Object,
    answer: String,
    child: Boolean,
    result:Object,
    repeatable:Boolean,
    repeatIndex:Number,
  },
  methods:{
    getAnswer(field_name) {
      if (!this.result) return '-';
      if(this.repeatable) {
        return get(this.result, field_name+this.repeatIndex, '-') ?? '-';
      } else {
        return get(this.result, field_name, '-') ?? '-';
      }
    }
  }
};
</script>

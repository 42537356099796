<template>
  <div class="flex flex-col flex-1">
    <Topbar :pages="pages"></Topbar>
    <div class="flex flex-col p-6 pb-0 flex-1">
      <div v-for="section in survey_sections" v-bind:key="section.id">
        <div
          class="bg-white shadow overflow-hidden sm:rounded-lg mb-2"
          v-if="Object.keys(section.section_content).length"
        >
          <div
            v-if="
              Object.keys(section.section_content).length &&
                section.section_content.repeatable &&
                parseInt(surveyResult.survey_result[section.section_content.section_repeat_field]) >
                  0
            "
          >
            <div
              v-for="i in parseInt(
                surveyResult.survey_result[section.section_content.section_repeat_field],
              )"
              :key="i"
            >
              <div class="px-4 py-5 sm:px-6">
                <h3 class="text-l font-bold mb-2 text-gray-600">
                  {{ section.section_content.section_name }} - Part - {{ i }}
                </h3>
                <div class="px-4 py-5 sm:p-0 border rounded border-red-400">
                  <Question
                    v-for="question in section.section_content.questions"
                    :question="question"
                    :repeatable="true"
                    :repeat-index="i - 1"
                    :result="surveyResult.survey_result"
                    :child="!!question.children.questions.length"
                    v-bind:key="question.name"
                  >
                  </Question>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              Object.keys(section.section_content).length && !section.section_content.repeatable
            "
          >
            <div class="px-4 py-5 sm:px-6">
              <h3 class="text-l font-bold mb-2 text-gray-600">
                {{ section.section_content.section_name }}
              </h3>
              <div class="px-4 py-5 sm:p-0 border rounded border-red-400">
                <Question
                  v-for="question in section.section_content.questions"
                  :question="question"
                  :result="surveyResult.survey_result"
                  :child="!!question.children.questions.length"
                  v-bind:key="question.name"
                >
                </Question>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-1" v-if="surveyResult">
      <div
        class="border-b border-gray-200 px-4 py-4 text-right"
        v-if="surveyResult.mode === 'submitted' && userGroup.includes('Approver')"
      >
        <button
          :disabled="approving"
          class="bg-green-800 text-white px-4 py-2"
          @click="handleApprove"
        >
          <template v-if="approving">
            Approving
          </template>
          <template v-if="!approving">
            Approve
          </template>
        </button>

        <button
          :disabled="drafting"
          class="bg-green-800 text-white px-4 py-2 ml-2"
          @click="handleDraft"
        >
          <template v-if="drafting">
            Converting
          </template>
          <template v-if="!drafting">
            Draft
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash-es/orderBy';
import map from 'lodash-es/map';
import API, {graphqlOperation} from '@aws-amplify/api';
import {getSurvey, getSurveyResult, listSurveySections} from '@/graphql/queries';
import Question from './components/Question';
import {mapGetters} from 'vuex';

export default {
  components: {
    Question,
  },
  data() {
    return {
      survey: null,
      surveyResult: null,
      isLoading: false,
      survey_sections: null,
      userGroup: null,
      approving: false,
      drafting: false,
    };
  },
  computed: {
    ...mapGetters({
      group: 'app/getGroup',
    }),
    id() {
      return this.$route.params.id;
    },
    result() {
      return this.$route.params.result;
    },
    pages() {
      return [
        {
          name: 'Surveys',
          to: {name: 'surveys'},
          current: false,
        },
        {
          name: this.survey ? this.survey.survey_name : 'Surveys View',
          to: {name: 'surveys.results', params: {id: this.id}},
          current: false,
        },
        {
          name: 'Result View',
          to: {name: 'surveys.view', params: {id: this.id, result: this.result}},
          current: true,
        },
      ];
    },
  },
  methods: {
    async loadSurvey() {
      await API.graphql(graphqlOperation(getSurvey, {id: this.id})).then(
        async ({data: {getSurvey}}) => {
          this.survey = getSurvey;
          this.survey.survey_content_parsed = JSON.parse(this.survey.survey_content);
          await API.graphql(graphqlOperation(listSurveySections, {survey_id: this.id})).then(
            ({
              data: {
                listSurveySections: {items},
              },
            }) => {
              this.survey_sections = map(orderBy(items, 'ordering'), section => {
                section.section_content = JSON.parse(section.section_content);
                return section;
              });
              this.isLoading = false;
            },
          );
        },
      );
    },
    async loadResult() {
      await API.graphql(graphqlOperation(getSurveyResult, {id: this.result})).then(
        async ({data: {getSurveyResult}}) => {
          this.surveyResult = getSurveyResult;
          this.surveyResult.survey_result = JSON.parse(getSurveyResult.survey_result);
        },
      );
    },
    async handleApprove() {
      this.approving = true;
      const customInput = {
        id: this.surveyResult.id,
        mode: 'completed',
        _version: this.surveyResult._version,
      };
      const updateSurveyResult = `mutation updateSurveyResult($input: UpdateSurveyResultInput!){
        updateSurveyResult(input: $input) {
            id
            mode
            survey_result
            survey_detail
            _version
            _lastChangedAt
        }
    }`;
      await API.graphql(graphqlOperation(updateSurveyResult, {input: customInput}));
      this.approving = false;
      this.isLoading = true;
      await this.loadResult();
      this.isLoading = false;
    },
    async handleDraft() {
      this.drafting = true;
      const customInput = {
        id: this.surveyResult.id,
        mode: 'draft',
        _version: this.surveyResult._version,
      };
      const updateSurveyResult = `mutation updateSurveyResult($input: UpdateSurveyResultInput!){
        updateSurveyResult(input: $input) {
            id
            mode
            survey_result
            survey_detail
            _version
            _lastChangedAt
        }
    }`;
      await API.graphql(graphqlOperation(updateSurveyResult, {input: customInput}));
      this.drafting = false;
      this.isLoading = true;
      await this.loadResult();
      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.loadResult();
    await this.loadSurvey();
    this.isLoading = false;
  },
  async created() {
    this.userGroup = await this.group;
  },
};
</script>
